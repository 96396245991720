exports.onRouteUpdate = async ({ location, prevLocation }) => {
  if (process.env.NODE_ENV !== 'production') {
    return
  }

  const referrer = prevLocation ? prevLocation.href : document.referrer
  const url = location.href
  const { language } = navigator
  let resolution = undefined
  if (window.screen && window.screen.width && window.screen.height) {
    resolution = [window.screen.width, window.screen.height]
  }

  try {
    await fetch('/api/info', {
      method: 'POST',
      body: JSON.stringify({
        referrer,
        page: {
          url,
        },
        language,
        resolution,
      }),
    })
  } catch (_err) {}
}
