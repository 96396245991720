/** @jsx jsx */

import React, { ReactNode } from 'react'
import { Flex, jsx } from 'theme-ui'
import { mdxMargin, mdxText } from '../../../../../src/components/mdx/common'
import { gradientMixin } from '../../../../../src/components/TextGradient'

interface BulletProps {
  className?: string

  size: 'big' | 'small'
}

const Bullet = React.memo<BulletProps>(props => {
  const size = props.size === 'big' ? '30px' : '20px'

  return (
    <Flex
      className={props.className}
      sx={{
        width: size,
        height: size,
        borderRadius: size,
        ...gradientMixin,
      }}
    />
  )
})

interface TriangleProps {
  className?: string
}

const Triangle = React.memo<TriangleProps>(props => {
  const size = '10px'

  return (
    <Flex
      className={props.className}
      sx={{
        width: '0px',
        height: '0px',
        borderTop: `${size} solid transparent`,
        borderBottom: `${size} solid transparent`,
        borderLeft: theme => `${size} solid ${theme.colors.primary1}`,
      }}
    />
  )
})

const TimelineItem = React.memo<{
  children?: ReactNode
  bulletText: string
  bullet: ReactNode
  image: string
  imageLink: string
}>(props => {
  return (
    <Flex
      sx={{
        position: 'relative',
        pl: 3,
        mb: 5,
        alignItems: 'center',
        flexDirection: ['column', 'row'],
      }}
    >
      <span
        sx={{
          position: 'absolute',
          left: '0px',
          top: '50%',
          transform: 'translate(calc(-100% - 20px), -50%)',
          fontSize: ['10px', 0],
        }}
      >
        {props.bulletText}
      </span>
      {props.bullet}

      <Flex
        as="a"
        aria-label="Site de l'établissement"
        href={props.imageLink}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ flexShrink: 0 }}
      >
        <img
          src={props.image}
          sx={{ mr: 3, width: '100px', height: '100px' }}
          alt=""
        />
      </Flex>

      {props.children}
    </Flex>
  )
})

interface ExperienceProps {
  children?: ReactNode

  bulletText: string
  image: string
  imageLink: string
  name: string
  location: string
  timespan: string
}

const Experience = React.memo<ExperienceProps>(props => {
  const bullet = React.useMemo(
    () => (
      <Bullet
        size="small"
        sx={{
          position: 'absolute',
          left: '0px',
          top: '50%',
          transform: 'translate(calc(-50% - 2.5px), -50%)',
        }}
      />
    ),
    []
  )

  return (
    <TimelineItem
      bulletText={props.bulletText}
      bullet={bullet}
      image={props.image}
      imageLink={props.imageLink}
    >
      <Flex sx={{ flexDirection: 'column' }}>
        <span sx={{ fontWeight: 'bold' }}>{props.name}</span>
        <span>{props.location}</span>
        <span sx={{ color: 'lightText' }}>{props.timespan}</span>
        <p>{props.children}</p>
      </Flex>
    </TimelineItem>
  )
})

interface EventProps {
  bulletText: string
  image: string
  imageLink: string
  name: string
  location: string
  description: string
}

const Event = React.memo<EventProps>(props => {
  const bullet = React.useMemo(
    () => (
      <Triangle
        sx={{
          position: 'absolute',
          left: '0px',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      />
    ),
    []
  )

  return (
    <TimelineItem
      bulletText={props.bulletText}
      bullet={bullet}
      image={props.image}
      imageLink={props.imageLink}
    >
      <Flex sx={{ flexDirection: 'column' }}>
        <span sx={{ fontWeight: 'bold' }}>{props.name}</span>
        <span>{props.location}</span>
        <span sx={{ color: 'lightText' }}>{props.description}</span>
      </Flex>
    </TimelineItem>
  )
})

interface TimelineProps {
  children: typeof Experience[]
}

const Timeline = React.memo<TimelineProps>(props => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        borderLeft: theme => `5px solid ${theme.colors.primary2}`,
        pt: 4,
        ml: ['80px', '110px'],
        position: 'relative',
        ...mdxText,
        ...mdxMargin,
      }}
    >
      <span
        sx={{
          position: 'absolute',
          left: '0px',
          top: '0px',
          transform: 'translate(calc(-100% - 25px), -50%)',
          fontSize: ['10px', 0],
        }}
      >
        Aujourd'hui
      </span>

      <Bullet
        size="big"
        sx={{
          position: 'absolute',
          left: '0px',
          top: '0px',
          transform: 'translate(calc(-50% - 2.5px), -50%)',
        }}
      />
      {props.children}
    </Flex>
  )
})

export { Experience, Timeline, Event }
