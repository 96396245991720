export const mdxText = {
  fontFamily: 'body',
  fontWeight: 'body',
  letterSpacing: 1,
  lineHeight: 2,
}

export const mdxMargin = {
  my: 4,
}
