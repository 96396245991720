import { Timeline, Event, Experience } from "../../../../content/posts/pages/experience/components/Timeline";
import intechLogoPng from "../../../../content/posts/pages/experience/intech_logo.png";
import izziLogoPng from "../../../../content/posts/pages/experience/izzi_logo.png";
import iziworkLogoPng from "../../../../content/posts/pages/experience/iziwork_logo.png";
import lyceeLogoPng from "../../../../content/posts/pages/experience/lycee_logo.png";
import payfitLogoPng from "../../../../content/posts/pages/experience/payfit_logo.png";
import sharingcloudLogoPng from "../../../../content/posts/pages/experience/sharingcloud_logo.png";
import * as React from 'react';
export default {
  Timeline,
  Event,
  Experience,
  intechLogoPng,
  izziLogoPng,
  iziworkLogoPng,
  lyceeLogoPng,
  payfitLogoPng,
  sharingcloudLogoPng,
  React
};