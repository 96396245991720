/** @jsx jsx */
import React from 'react'
import { jsx } from 'theme-ui'

interface Props {
  children: React.ReactNode
}

export const gradientMixin = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  background: (theme: any) =>
    `linear-gradient(45deg, ${theme.colors.primary1}, ${theme.colors.primary2})`,
}

export const textGradientMixin = {
  ...gradientMixin,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}

const TextGradient = React.memo<Props>(props => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <span sx={textGradientMixin as any}>{props.children}</span>
))

export { TextGradient }
